<template>
    <el-input
        v-model="data[item.key]"
        :placeholder="item.placeholder || ''"
        :readonly="item.readonly || false"
        :type="item.inputType || 'text'"
        :disabled="item.disabled || false"
        :maxlength="item.maxlength || -1"
    >
        <template slot="append" v-if="item.append">{{ item.append }}</template>
    </el-input>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: null
        },
        item: {
            type: Object,
            default: null
        }
    }
};
</script>